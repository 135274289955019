import React, {useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, getTanentProfile } from '../../redux/thunks/UserProfile';
import CryptoJS from 'crypto-js';
import hexDigest from 'crypto-js/enc-hex';
import { GenerateHash } from '../../redux/thunks/CustomerSupport';

export default function ChatwootComponent({ children }) {

	const baseUrl = window._env_.CHATWOOT_BASE_URL;
	const chatwootWebsiteToken = window._env_.CHATWOOT_WEB_ACCESS_TOKEN;
	const candidateChatwootWebsiteToken = window._env_.CANDIDATE_CHATWOOT_WEB_ACCESS_TOKEN;
	const { userProfileData, data } = useSelector((state) => state.userProfileReducer); //here data is for tanent profile data
	const auth = useSelector((state) => state.authReducer);
	const dispatch = useDispatch();
	const USER_NAME = `${userProfileData?.first_name} ${userProfileData?.last_name}`;
	
	// const [hash, setHash] = useState(null);
	const [isWidgetInitialized, setIsWidgetInitialized] = useState(false);

	useEffect(() => {
		if (auth.data?.access_token) {
			dispatch(getUserProfile({}));
			if (auth.data?.role?.id !== 'candidate') {
				dispatch(getTanentProfile({ id: '', isCallForCurrAssessment: false }));
			}
		}
	}, [auth.data?.access_token]);

	useEffect(() => {
		const loadChatwootScript = () => {
			if (auth?.app_state === 'loading') return;
			const g = document.createElement('script');
			const s = document.getElementsByTagName('script')[0];
			g.src = `${baseUrl}/packs/js/sdk.js`;
			g.defer = true;
			g.async = true;
			s.parentNode.insertBefore(g, s);

			g.onload = async () => {
				const websiteToken = auth?.data?.role?.name === 'Candidate' ? candidateChatwootWebsiteToken : chatwootWebsiteToken;
				
				window.chatwootSDK.run({
					websiteToken: websiteToken,
					baseUrl: baseUrl
				});
				if (auth?.data?.access_token && userProfileData ) {	
					const result =  await dispatch(GenerateHash());
					const hash = result.payload[1].hash;
					
					if (hash)
					{
						window.$chatwoot.setUser(auth?.data?.user_id.toString(), {
							email: auth?.data?.email,
							name: USER_NAME,
							identifier_hash: hash,
						});
					}
				}
			};
		
		};
		// Check if document object is available (client-side rendering)
		if (typeof document !== 'undefined') {
			loadChatwootScript();
		}
	}, [baseUrl, chatwootWebsiteToken, candidateChatwootWebsiteToken, auth?.data?.role?.name, auth.data.access_token, userProfileData, data, isWidgetInitialized]);
	
	useEffect(() => {
		if (auth?.app_state === 'ready') {
			setIsWidgetInitialized(true);
		}
	}, [auth?.data?.role?.name]);

	return (
		<div>
			{children}
		</div>
	);
}