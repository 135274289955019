import React, { useState } from 'react';
import CustomModal from '../../../common/Modal';
import Input from '../../../../components/sub-component/Input';
import fontSrc from '../../../../assets/font/Open_Sans/static/OpenSans-Light.ttf';
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken } from '../../../../redux/thunks/Auth';
import { createPaymentMethod, createSubscription, getPaymentMethods } from '../../../../redux/thunks/Subscription';
import {
	CardNumberElement,
	useStripe,
	useElements,
	CardExpiryElement,
	CardCvcElement,
} from '@stripe/react-stripe-js';

import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '../../../sub-component/Button';
import AlertBox from '../../../common/AlertBox';

export default function BillingSettingsAddCardModal(props) {
	const dispatch = useDispatch();
	const [cardError, setCardError] = useState({});
	const [alert, setAlert] = useState(null);
	const [stripeElementsValidation, setStripeElementsValidation] = useState({});
	const stripe = useStripe();
	const elements = useElements();
	const subscription = useSelector((state) => state.subscriptionReducer);
	
	const cardNumberStyle = {
		style: {

			fonts: [
				{
					src: `url(${fontSrc})`,
					family: 'Open Sans',
				},
			],
			base: {
				fontSize: '14px',
				fontWeight: 400,
				fontStyle: 'normal',
				lineHeight: '18px',
				fontFamily: 'Roboto',
				color: '#3E454D',
				'::placeholder': {
					fontFamily: 'Roboto',
					color: '#96999C',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		},
		showIcon: true,
	};

	const cardStyle = {
		style: {
			base: {
				fontSize: '14px',
				fontStyle: 'normal',
				fontFamily: 'Roboto',
				'::placeholder': {
					fontFamily: 'Roboto',
					color: '#96999C',
				},
				lineHeight: '20px',
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		},
	};
	
	const AntSwitch = styled(Switch)(({ theme }) => ({
		width: 50,
		height: 26,
		padding: 0,
		display: 'flex',
		'&:active': {
			'& .MuiSwitch-thumb': {
				width: 25,
			},
			'& .MuiSwitch-switchBase.Mui-checked': {
				transform: 'translateX(24px)',
			},
		},
		'& .MuiSwitch-switchBase': {
			padding: 1,
			'&.Mui-checked': {
				transform: 'translateX(24px)',
				color: '#fff',
				'& + .MuiSwitch-track': {
					opacity: 1,
					backgroundColor:
						theme.palette.mode === 'dark' ? '#177ddc' : '#392D69',
				},
			},
		},
		'& .MuiSwitch-thumb': {
			boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
			width: 24,
			height: 24,
			borderRadius: 12,
			transition: theme.transitions.create(['width'], {
				duration: 200,
			}),
		},
		'& .MuiSwitch-track': {
			borderRadius: 26 / 2,
			opacity: 1,
			backgroundColor:
				theme.palette.mode === 'dark'
					? 'rgba(255,255,255,.35)'
					: 'rgba(0,0,0,.25)',
			boxSizing: 'border-box',
		},
	}));
	const { open, plan, addCard, setIsAddCardModalOpen, setTrigerEvent, trigerEvent, setLoader } = props;
	const [cardDetails, setCardDetails] = useState({
		cardNumber: '',
		name: '',
		cardExpiry: '',
		cardCVC: '',
		isDefault: true
	});
	const handleCloseModal = () => {
		setIsAddCardModalOpen(false);
		setStripeElementsValidation({});
		setCardDetails({
			cardNumber: '',
			name: '',
			cardExpiry: '',
			cardCVC: '',
			isDefault: true
		});
		setCardError({});
	};
	const handleChange = (name, value) => {
		setCardDetails({ ...cardDetails, [name]: value });
	};
	const validateStripeForm = (cardInfo) => {
		setStripeElementsValidation({ ...stripeElementsValidation, [cardInfo.name]: cardInfo?.value });
	};
	const isFormValid = () => {

		var isValid = true;

		const _credentials = {
			name: cardDetails.name,
			cardNumber: stripeElementsValidation.cardNumber,
			cardExpiry: stripeElementsValidation.cardExpiry,
			cardCVC: stripeElementsValidation.cardCVC,
		};


		var _error = {};
		Object.keys(_credentials).map(key => {
			if (_credentials[key] === null || _credentials[key] === '' || _credentials[key] == undefined) {
				_error = { ..._error, [key]: `${key.replace('_', ' ')} is required` };
				_error = { ..._error, [key]: `${key === 'cardNumber' ? 'Card Number is required.' : key === 'name' ? 'Name is required.' : key === 'cardExpiry' ? `Card Expiry is required.` : key === 'cardCVC' && `Card CVC is required.`}` };
				isValid = false;
			} else if (_credentials[key] == false) {
				_error = { ..._error, [key]: `${key === 'cardNumber' ? 'Your card number is incomplete.' : key === 'cardExpiry' ? `Your card's expiration date is incomplete.` : key === 'cardCVC' && `Your card's security code is incomplete.`}` };
				isValid = false;
			} else {
				_error = { ..._error, [key]: null };
			}
		});
		setCardError(_error);
		return isValid;


	};
	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!isFormValid()) {
			return;
		}

		const data = {
			...cardDetails,
		};
		if (addCard) {
			const { payload, error, meta } = await dispatch(createPaymentMethod({ stripe, elements, ...data, CardNumberElement, CardCvcElement }));
			if (error) {
				setAlert(
					<AlertBox description={error.type === 'card_error' ? `Card Error: ${error.message}` : payload.error_type === 'StripeCardError' ? `Card Error: ${payload.message}` : meta?.requestStatus === 'rejected' ? `Card Error: Your card detail is incomplete!` : `Generic Error: Please refresh and try again. Contact support for persistent errors.`} color='danger' title='Error' onCloseAlert={() => { setAlert(null); }} />
				);
				return;
			}
		} else {
			const { payload, error, meta } = await dispatch(createPaymentMethod({ stripe, elements, ...data, CardNumberElement, CardCvcElement }));
			if (error) {
				setAlert(
					<AlertBox description={error.type === 'card_error' ? `Card Error: ${error.message}` : payload.error_type === 'StripeCardError' ? `Card Error: ${payload.message}` : meta?.requestStatus === 'rejected' ? `Card Error: Your card detail is incomplete!` : `Generic Error: Please refresh and try again. Contact support for persistent errors.`} color='danger' title='Error' onCloseAlert={() => { setAlert(null); }} />
				);
				return;
			}
			await dispatch(createSubscription({ stripe_product_id: plan.id, interval: plan?.interval }));
		}
		dispatch(refreshToken());
		dispatch(getPaymentMethods());
		setCardDetails({
			cardNumber: '',
			name: '',
			cardExpiry: '',
			cardCVC: '',
			isDefault: true
		});
		setIsAddCardModalOpen(false);
		setTrigerEvent(!trigerEvent);
		setLoader(true);
	};

	return (
		<CustomModal
			open={open}
			handleCloseModal={handleCloseModal}
			confirmBtnTitle='Add Card'
			processing={subscription?.processing || subscription?.is_loading}
			onConfirm={handleSubmit}
			varrient='alert-warning-modal'
			warningAlert={true}
			column={true}
			title='Add New Card'
			description='Add card for further billing'
		>
			{alert}
			<div className='add-card-modal'>
				<div className='card-number'>
					<label className='description body-2 dark-50' style={{marginLeft:'24px'}}>Card Number</label>
					<CardNumberElement
						className='stripe-input-field-full'
						options={cardNumberStyle}
						onChange={(e) => {
							if (e?.error) {
								setCardError({ ...cardError, cardNumber: e?.error?.message });
							} else {
								setCardError({ ...cardError, cardNumber: '' });
								validateStripeForm({
									name: 'cardNumber',
									value: e?.empty ? undefined : !e?.complete ? false : true,
								});
							}
						}}
					/>
					<div className='description danger-text'>
						{cardError?.cardNumber}
					</div>
				</div>
				<div className='card-detail'>
					<div className='card-name'>
						<label className='description body-2 dark-50 ' style={{marginLeft:'24px'}}>Name On Card</label>
						<Input
							varrient='primary'
							// className='input body-3 primary-input'
							placeholder='Enter name'
							value={cardDetails.name}
							onChange={(e) => handleChange('name', e.target.value)}
							// style={{border: '0.2px solid', borderRadius: '4px'}}
						/>
						<div className='description danger-text'>
							{cardError?.name}
						</div>
					</div>
					<div className='card-date-cvv d-flex flex-row'>
						<div className='card-date'>
							<label className='description body-2 dark-50 ' style={{marginLeft:'16px'}}>Expiration Date</label>
							<CardExpiryElement
								className='stripe-input-field-full'
								options={cardStyle}
								onChange={(e) => {
									if (e?.error) {
										setCardError({ ...cardError, cardExpiry: e?.error?.message });
									} else {
										setCardError({ ...cardError, cardExpiry: '' });
										validateStripeForm({
											name: 'cardExpiry',
											value: e?.empty ? undefined : !e?.complete ? false : true,
										});
									}
								}}
							/>
							<div className='description danger-text'>
								{cardError?.cardExpiry}
							</div>
						</div>
						<div className='card-cvv'>
							<label className='description body-2 dark-50 ' style={{marginLeft:'16px'}}>CVC</label>
							<CardCvcElement
								className='stripe-input-field-full ms-auto'
								options={cardStyle}
								onChange={(e) => {
									if (e?.error) {
										setCardError({ ...cardError, cardCVC: e?.error?.message });
									} else {
										setCardError({ ...cardError, cardCVC: '' });
										validateStripeForm({
											name: 'cardCVC',
											value: e?.empty ? undefined : !e?.complete ? false : true,
										});
									}
								}}
							/>
							<div className='description danger-text'>
								{cardError?.cardCVC}
							</div>
						</div>
					</div>
				</div>
				{/* <div className='card-switch d-flex flex-row align-items-center'>
					<AntSwitch
						className='switch'
						defaultChecked={addCard ? cardDetails.isDefault : true}
						inputProps={{ 'aria-label': 'ant design' }}
						onClick={() => { setCardDetails({ ...cardDetails, isDefault: !cardDetails.isDefault }); }}
					/>
					<span className='body-3'>Set As Default Payment Method?</span>
				</div> */}
			</div>
		</CustomModal>
	);
}
