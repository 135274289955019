import { createSlice } from '@reduxjs/toolkit';
import { GetAPIAccessToken, CreateAPIAccessToken, RevokeAPIAccessToken, GetAPITokenPermissions } from '../thunks/APIAccessToken';

const APIAccessTokenSlice = createSlice({
    name: 'APIAccessTokenSlice',
    initialState: {
        loading: false,
        processing: false,
        data: null,
        current_api_access_token: null,
        api_token_permissions: null,
        error_message: null,
        success_message: null,
    },

    extraReducers: {
        [GetAPIAccessToken.pending]: (state) => {
            return {
                ...state,
                processing: true,
            };
        },
        [GetAPIAccessToken.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                processing: false,
                data: payload,
            };
        },
        [GetAPIAccessToken.rejected]: (state) => {
            return {
                ...state,
                processing: false,
            };
        },

        [CreateAPIAccessToken.pending]: (state) => {
            return {
                ...state,
                processing: true,
            };
        },
        [CreateAPIAccessToken.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                processing: false,
                current_api_access_token: payload,
            };
        },
        [CreateAPIAccessToken.rejected]: (state) => {
            return {
                ...state,
                processing: false,
            };
        },

        [RevokeAPIAccessToken.pending]: (state) => {
            return {
                ...state,
                processing: true,
            };
        },
        [RevokeAPIAccessToken.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                processing: false,
                current_api_access_token: payload,
            };
        },
        [RevokeAPIAccessToken.rejected]: (state) => {
            return {
                ...state,
                processing: false,
            };
        },

        [GetAPITokenPermissions.pending]: (state) => {
            return {
                ...state,
                processing: true,
            };
        },
        [GetAPITokenPermissions.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                processing: false,
                api_token_permissions: payload,
            };
        },
        [GetAPITokenPermissions.rejected]: (state) => {
            return {
                ...state,
                processing: false,
            };
        },
    },
});

export default APIAccessTokenSlice.reducer;