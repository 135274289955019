import React, { useState } from 'react';
// import { useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

// import logo from '../../assets/icons/logo.png';
import Button from './../components/sub-component/Button';
import OnboardingBox from '../components/OnboardingBox';
import { useDispatch, useSelector } from 'react-redux';
import * as Constant from '../utils/Constants';
// import * as Utils from '../utils/utilities';
import { resendActivationToken } from '../redux/thunks/Auth';
import AlertBox from '../components/common/AlertBox';
import { Link } from 'react-router-dom';
import { removeAllCookies } from '../utils/utilities';
import { useMediaQuery } from '@mui/material';
// import { ActivationState } from '../utils/Constants';

function ActivationPending() {
	// const history = useHistory();

	const dispatch = useDispatch();
	const search = useLocation().search;
	const { email } = useSelector((state) => state?.authReducer?.data);
	const [alert, setAlert] = useState('');
	const [processing, setProcessing] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	const resendLink = async () => {
		setProcessing(true);
		setDisabled(true);
		const { payload, meta } = await dispatch(resendActivationToken({ email }));
		setProcessing(false);

		if (meta.requestStatus === Constant.RequestStatus.REJECTED) {
			setAlert(<AlertBox description={`${payload}!`} color="danger" title="Error" onCloseAlert={() => { setAlert(null); }} />);
			return;
		}
		setAlert(<AlertBox description={`${payload}!`} color="success" title="Success" onCloseAlert={() => { setAlert(null); }} />);
		setTimeout(() => {
			setDisabled(false);
		}, [10000]);
	};
	// const backToLogin = () => {
	// 	// Utils.deleteCookie(Constant.tokenCookieName);
	// 	window.location.reload();
	// };

	return (
		<OnboardingBox>
			<div className="d-flex flex-column signup-form-container" style={{ padding: '0px' }}>
				<div className="form w-100">
					<div className="alert-wrapper mb-3">{alert}</div>
					<div className={`${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-3'}`}>Verify Your Email</div>
					<div className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '2'} dark-50`} style={{ marginTop: '10px', lineHeight: '24px' }}>
						{/* We&apos;ve sent a link to your email address: */}
						{`We've sent a link to your email address: `}
						<span className="link-to-login pointer primary-text ">
							{email}.
						</span>
						&nbsp;Please follow the link inside to continue.
					</div>

					<div className="" style={{ marginTop: '32px' }}>
						<Button
							rectangle-button
							onClick={resendLink}
							title={'Resend Link'}
							processing={processing}
							disabled={disabled}
							style={{ height: '40px' }}
						/>
					</div>

					<Link to={'/login'} style={{ textDecoration: 'none' }}>
						<div
							className="description body-2 text-center primary-text w-100 pointer" style={{ marginTop: '18px' }}
							onClick={() => {
								removeAllCookies();
								window.location.replace(`/${search || ''}`);
							}}
						>
							{`< Back to login`}
						</div>
					</Link>
				</div>
			</div>
		</OnboardingBox >
	);
}
export default ActivationPending;
