import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const GetAPIAccessToken = createAsyncThunk(
    'API_Access_Token/GetAPIAccessToken',
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const request = await secure_instance.request({
                url: `/v1/api_keys/`,
                method: 'GET',
            });

            return request?.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    },
);

export const CreateAPIAccessToken = createAsyncThunk(
    'API_Access_Token/CreateAPIAccessToken',
    async ({ data }, { rejectWithValue, dispatch }) => {
        try {
            const request = await secure_instance.request({
                url: `/v1/api_keys/`,
                method: 'POST',
                data: data,
            });

            return request?.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    },
);

export const RevokeAPIAccessToken = createAsyncThunk(
    'API_Access_Token/RevokeAPIAccessToken',
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {
            const request = await secure_instance.request({
                url: `/v1/api_keys/${id}/`,
                method: 'PATCH',
            });

            return request?.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    },
);

export const GetAPITokenPermissions = createAsyncThunk(
    'API_Access_Token/GetAPITokenPermissions',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const request = await secure_instance.request({
                url: `/v1/api_permissions/`,
                method: 'GET',
            });

            return request?.data;
        } catch (error) {
            return handleApiError(error, rejectWithValue, dispatch);
        }
    },
);