import React from 'react';
import { PathType, UserRole } from './Constants';
import SignIn from '../views/SignIn';
import SignUp from '../views/SignUp';
import CompanyInfo from '../views/CompanyInfo';
import AccountActivation from '../views/AccountActivation';
// import Entry from '../views/Entry';
import ActivationPending from '../views/ActivationPending';
//TODO: remove comments when there is a need to enable subscription
// import Subscription from '../views/Subscription';
import ResetPassword from '../views/ResetPassword';
import ForgetPassword from '../views/ForgetPassword';
import Dashboard from '../views/Dashboard';
// import Assessment from '../views/dashboard/Assessment';
import AssessmentPage from '../views/AssessmentPage';
import CompoentView from '../components/CompoentView';
import AssessmentSpecsView from '../views/dashboard/assessments/assessment-creation/AssessmentSpecsView';
import CandidateAssessFlow from '../views/candidate-assessment-flow/CandidateAssessFlow';
import TeamSettings from '../components/dashboard/settings/team-settings/TeamSettings';
import Settings from '../views/Settings';
import APIAccessToken from '../views/api-access-token/APIAccessToken';
import Notifications from '../components/dashboard/notifications/Notifications';
import ProcessAssessmentInvite from '../views/ProcessAssessmentInvite';
import AssessmentDetailsPage from '../views/AssessmentDetailsPage';
import CompanySettings from '../components/dashboard/settings/CompanySettings';
import BillingSettings from '../components/dashboard/settings/BillingSettings/BillingSettings';
import CandidateListingView from '../views/dashboard/candidates/CandidateListingView';
import CandidateDetails from '../views/dashboard/candidates/CandidateDetails';
import AssessmentCriteria from '../views/dashboard/test-details/AssessmentCriteria';
import CandidatePage from '../views/CandidatePage';
import ProfileSettings from '../views/candidates/settings/ProfileSettings';
// import CandidateDashboard from '../views/candidates/dashboard/CandidateDashboard';
import AdminAssessments from '../views/dashboard/assessments/AdminAssessments';
import PublishAssessment from '../views/dashboard/assessments/assessment-publish/PublishAssessment';
import OAuthScreen from '../views/OAuthScreen';
import { ReactComponent as AssessmentIcon } from '../assets/icons/components/sidebar/clipboard.svg';
import { ReactComponent as CandidateIcon } from '../assets/icons/components/sidebar/candidate.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/components/sidebar/settings.svg';
import { ReactComponent as APIAccessTokenIcon } from '../assets/icons/components/sidebar/api-access-token.svg';
import { ReactComponent as PlusIcon } from '../assets/icons/components/sidebar/plus.svg';
import { ReactComponent as MinusIcon } from '../assets/icons/components/sidebar/minus.svg';
import { ReactComponent as ActiveAssessIcon } from '../assets/icons/components/active.svg';
import { ReactComponent as DraftAssessIcon } from '../assets/icons/components/sidebar/file.svg';
import { ReactComponent as ArchiveAssessIcon } from '../assets/icons/components/sidebar/archive.svg';
import { ReactComponent as UserSettingIcon } from '../assets/icons/components/sidebar/user.svg';
import { ReactComponent as CompanySettingIcon } from '../assets/icons/components/sidebar/briefcase.svg';
import { ReactComponent as TeamSettingIcon } from '../assets/icons/components/sidebar/users.svg';
import { ReactComponent as BillingSettingIcon } from '../assets/icons/components/sidebar/credit-card.svg';
import { ReactComponent as TestHistoryIcon } from '../assets/icons/components/sidebar/history-clock.svg';
import TestpackPreviewQuestions from '../views/preview-questions/TestpackPreviewQuestions';
import PublicAssessmentResult from '../views/public-result/PublicAssessmentResult';
import PlanPage from '../views/PlanPage';
import CandidateNameForm from '../views/CandidateNameForm';
import InviteLinkExpired from '../views/InviteLinkExpired';
import CandidateInvite from '../views/CandidateInvite';
import AssessmentUnavailable from '../views/AssessmentUnavailable';
import AssessmentConcluded from '../views/candidate-assessment-flow/Assessment-concluded/AssessmentConcluded';
import UnavailableAssessment from '../views/UnavailableAssessment';
import CandidateTestHistory from '../views/candidates/test-history/CandidateTestHistory';
// import UserSettings from '../components/dashboard/settings/UserSettings';
// import LinkExpired from '../views/SignUp/AccountActivation';
// import AccountActivation from '../views/SignUp/AccountActivation';
// import Subscription from '../views/SignUp/Subscription';
// import activeIcon from '../assets/icons/basic/active-rectangle.svg';


export const PathName = {
	MAIN: '/dashboard',
	LOGIN: '/login',
	SIGNUP: '/signup',
	COMPANY_INFO: '/company-info',
	PLAN_PAGE: '/plan-page',
	ACTIVATION_PENDING: '/activation',
	ACTIVATION: '/activation/:token',
	SUBSCRIPTION: '/subscription',
	FORGET_PASSWORD: '/forget-password',
	DASHBOARD: '/d',
	ASSESSMENTS: '/dashboard/assessments',
	CANDIDATES_TEST_HISTORY: '/dashboard/test-history',
	CANDIDATES: '/dashboard/candidates',
	CANDIDATESLIST: '/',
	SETTINGS: '/dashboard/settings',
	INTEGRATIONS: '/integrations',
	NEW_ASSESSMENT: '/new-assessment',
	COMPONENT_TESTING: '/component-testing',
	ACTIVE_ASSESSMENTS: '/active',
	DRAFT_ASSESSMENTS: '/draft',
	ARCHIVED_ASSESSMENTS: '/archived',
	PENDING_ASSESSMENTS: '/pending',
	COMPANY_SETTINGS: '/company',
	TEAM_SETTINGS: '/team',
	BILLING_SETTINGS: '/billing',
	API_ACCESS_TOKEN: '/api-access-token',
	NOTIFICATIONS: '/dashboard/notifications',
	PROCESS_ASSESSMENT_INVITE: '/process-invite',
	CANDIDATE_PROFILE_SETTINGS: '/dashboard/profile-settings',
	PUBLISH_ASSESSMENT: '/publish/:id',
	TESTPACK_PREVIEW_QUESTIONS: '/preview-questions',
	PUBLIC_ASSESSMENT_RESULT: '/assessment-result/:id',
	OAUTH: '/oauth',
	USER_PROFILE_SETTINGS: '/profile-settings',
	CANDIDATE_ACTIVATION: '/assessment_invitation',
	RESEND_INVITE: '/resend-link',
	EMAIL_INVITE: '/email-invite',
	UNAVAILABLE_ASSESSMENT: '/unavailable-assessment',
	ASSESSMENTS_UNAVAILABLE: '/assessment-unavailable'
	// CANDIDATE_FEEDBACK: '/candidate-feedback'
	// ASSESSMENT_CONCLUDED: '/assessment-concluded'
	// PUBLISH_ASSESSMENT: '/publish/:id',
	// DASHBOARD: '/dashboard'
	//Candidate Dashboard
	// CANDIDATE_DASHBOARD: '/c',
	// ACTIVE_ASSESSMENTS: '/?status=active',
	// DRAFT_ASSESSMENTS: '/?status=draft',
	// ARCHIVED_ASSESSMENTS: '/?status=archived',
	// PENDING_ASSESSMENTS: '/?status=pending',
};

export const ProperPathName = [
	'/dashboard',
	'/login',
	'/signup',
	'/company-info',
	'/plan-page',
	'/activation',
	'/activation/:token',
	'/subscription',
	'/forget-password',
	'/d',
	'/dashboard/assessments',
	'/dashboard/test-history',
	'/dashboard/candidates',
	'/',
	'/dashboard/settings',
	'/integrations',
	'/new-assessment',
	'/component-testing',
	'/dashboard/assessments/active',
	'/dashboard/assessments/draft',
	'/dashboard/assessments/archived',
	'/dashboard/assessments/pending',
	'/dashboard/assessments/:id',
	'/dashboard/assessments/draft/:id',
	'/dashboard/assessments/archived/:id',
	'/dashboard/assessments/pending/:id',
	'/dashboard/candidates',
	'/dashboard/candidates/:id',
	'/dashboard/settings/company',
	'/dashboard/settings/team',
	'/dashboard/settings/billing',
	'/dashboard/settings/api-access-token',
	'/dashboard/notifications',
	'/dashboard/settings/profile-settings',
	'/company',
	'/team',
	'/billing',
	'/dashboard/notifications',
	'/process-invite',
	'/dashboard/profile-settings',
	'/publish/:id',
	'/preview-questions',
	'/assessment-result/:id',
	'/oauth',
	'/profile-settings',
	'/assessment_invitation',
	'/assessment_invitation/:token',
	'/reset-password/:token',
	'/resend-link',
	'/resend-link/:token',
	'/email-invite',
	'/email-invite/:token',
	'/unavailable-assessment',
	'/assessment-unavailable',
	'/dashboard/assessments/create/:id',
	'/dashboard/assessments/publish/:id',
	'/preview-questions?test_id',
	'/?invite_id',
	'/email-invite?invite_id',
	'/assessment_invitation/?invite_token',
	'/dashboard/notifications',
	'/dashboard/test-history',
	'/dashboard/assessments/criteria/:id',
	'/signup/'


];

export const MainRoutes = Object.freeze({
	'': Object.freeze({
		pathname: PathName.MAIN,
		path_type: PathType.PROTECTED,
		exact: false,
		has_nested_routes: false,
		component: Dashboard,
	}),

	login: Object.freeze({
		pathname: PathName.LOGIN,
		path_type: PathType.AUTHENTICATION,
		has_nested_routes: false,
		component: SignIn,
	}),

	signup: Object.freeze({
		pathname: PathName.SIGNUP,
		path_type: PathType.AUTHENTICATION,
		has_nested_routes: false,
		component: SignUp,
	}),

	'company-info': Object.freeze({
		pathname: PathName.COMPANY_INFO,
		path_type: PathType.PROTECTED,
		has_nested_routes: false,
		component: CompanyInfo,
	}),

	'plan-page': Object.freeze({
		pathname: PathName.PLAN_PAGE,
		path_type: PathType.PROTECTED,
		has_nested_routes: false,
		component: PlanPage,
	}),

	'component-testing': Object.freeze({
		pathname: PathName.COMPONENT_TESTING,
		path_type: PathType.AUTHENTICATION,
		has_nested_routes: false,
		component: CompoentView,
	}),

	// 'activation-pending': Object.freeze({
	// 	pathname: PathName.ACTIVATION_PENDING,
	// 	path_type: PathType.ACTIVATION,
	// 	has_nested_routes: false,
	// 	exact: true,
	// 	component: ActivationPending,
	// }),

	activation: Object.freeze({
		pathname: PathName.ACTIVATION_PENDING,
		param_pathname: PathName.ACTIVATION,
		path_type: PathType.ACTIVATION,
		has_nested_routes: false,
		exact: true,
		component: ActivationPending,
		param_component: AccountActivation,
	}),

	// subscription: Object.freeze({
	// 	pathname: PathName.SUBSCRIPTION,
	// 	path_type: PathType.PROTECTED,
	// 	has_nested_routes: false,
	// 	component: Subscription,
	// }),

	oauth: Object.freeze({
		pathname: PathName.OAUTH,
		path_type: PathType.PROTECTED,
		has_nested_routes: false,
		component: OAuthScreen,
	}),

	'forget-password': Object.freeze({
		pathname: PathName.FORGET_PASSWORD,
		path_type: PathType.AUTHENTICATION,
		has_nested_routes: false,
		component: ForgetPassword,
	}),

	'reset-password': Object.freeze({
		pathname: '/reset-password/:token',
		path_type: PathType.AUTHENTICATION,
		has_nested_routes: false,
		component: ResetPassword,
	}),
	'assessment_invitation': Object.freeze({
		pathname: '/assessment_invitation',
		path_type: PathType.UNPROTECTED,
		has_nested_routes: false,
		component: CandidateNameForm,
	}),
	'resend-link': Object.freeze({
		pathname: '/resend-link',
		path_type: PathType.UNPROTECTED,
		has_nested_routes: false,
		component: InviteLinkExpired,
	}),
	'email-invite': Object.freeze({
		pathname: '/email-invite',
		path_type: PathType.UNPROTECTED,
		has_nested_routes: false,
		component: CandidateInvite,
	}),
	'unavailable-assessment': Object.freeze({
		pathname: '/unavailable-assessment',
		path_type: PathType.UNPROTECTED,
		has_nested_routes: false,
		component: UnavailableAssessment,
	}),
	'assessment-unavailable': Object.freeze({
		pathname: '/assessment-unavailable',
		path_type: PathType.UNPROTECTED,
		has_nested_routes: false,
		component: AssessmentUnavailable,
	}),

	'assessment-result': Object.freeze({
		pathname: '/assessment-result/:id',
		path_type: PathType.UNPROTECTED,
		has_nested_routes: false,
		component: PublicAssessmentResult,
	}),

	// '/assessment-concluded': Object.freeze({
	// 	pathname: '/assessment-concluded',
	// 	path_type: PathType.UNPROTECTED,
	// 	has_nested_routes: false,
	// 	component: AssessmentConcluded,
	// }),

	d: Object.freeze({
		pathname: PathName.DASHBOARD,
		path_type: PathType.PROTECTED,
		has_nested_routes: false,
		component: Dashboard,
	}),

	c: Object.freeze({
		pathname: PathName.CANDIDATE_DASHBOARD,
		path_type: PathType.PROTECTED,
		has_nested_routes: false,
		component: CandidateAssessFlow,
	}),
	// e: Object.freeze({
	// 	pathname: PathName.CANDIDATE_DASHBOARD,
	// 	path_type: PathType.PROTECTED,
	// 	has_nested_routes: false,
	// 	component: AssessmentConcluded,
	// }),


	'process-invite': Object.freeze({
		pathname: PathName.PROCESS_ASSESSMENT_INVITE,
		path_type: PathType.PROTECTED,
		has_nested_routes: false,
		component: ProcessAssessmentInvite,
	}),

	'testpack-preview-questions': Object.freeze({
		pathname: PathName.TESTPACK_PREVIEW_QUESTIONS,
		path_type: PathType.PROTECTED,
		has_nested_routes: false,
		component: TestpackPreviewQuestions,
	}),

	// 'reference-form': Object.freeze({
	// 	pathname: '/reference-form/:token',
	// 	path_type: PathType.UNPROTECTED,
	// 	has_nested_routes: false,
	// 	component: <ReferenceForm />,
	// }),

	// 'assessment-reports': Object.freeze({
	// 	pathname: '/assessment_reports/:id',
	// 	path_type: PathType.UNPROTECTED,
	// 	has_nested_routes: false,
	// 	exact: true,
	// 	component: <CandidateAssessmentReport />,
	// }),

	// 'assessment-invite': Object.freeze({
	// 	pathname: '/assessment-invite/:linkId',
	// 	path_type: PathType.UNPROTECTED,
	// 	has_nested_routes: false,
	// 	exact: true,
	// 	component: <AssessmentInvite />,
	// }),

	// activation: Object.freeze({
	// 	pathname: '/activation/:token',
	// 	path_type: PathType.PROTECTED,
	// 	has_nested_routes: false,
	// 	// eslint-disable-next-line react/display-name
	// 	component: AccountActivation,
	// }),

	// subscription: Object.freeze({
	// 	pathname: '/subscription',
	// 	path_type: PathType.PROTECTED,
	// 	has_nested_routes: false,
	// 	// eslint-disable-next-line react/display-name
	// 	component: Subscription,
	// }),

	// dashboard: Object.freeze({
	// 	pathname: '/dashboard',
	// 	path_type: PathType.PROTEDTED,
	// 	has_nested_routes: true,
	// 	// eslint-disable-next-line react/display-name
	// 	component: Dashboard,
	// }),

	// suspended: Object.freeze({
	// 	pathname: '/account-suspended',
	// 	path_type: PathType.PROTEDTED,
	// 	has_nested_routes: false,
	// 	component: <AccountSuspended />,
	// }),

	// error: Object.freeze({
	// 	pathname: '/error',
	// 	path_type: PathType.PROTEDTED,
	// 	has_nested_routes: false,
	// 	// eslint-disable-next-line react/display-name
	// 	component: () => <h3>Error Path</h3>,
	// }),
});

export const AssessmentsRoutes = {
	active_assessments: {
		text: 'Active',
		pathname: PathName.ACTIVE_ASSESSMENTS,
		exact: true,
		is_menu_item: true,
		required_roles: [
			UserRole.OWNER,
			UserRole.ADMIN,
			UserRole.RECRUITER,
			UserRole.HIRING_MANAGER,
			UserRole.MEMBER,
		],
		iconClass: <ActiveAssessIcon style={{ width: '30px', height: '20px' }} />,
		component: AdminAssessments,
	},

	draft_assessments: {
		text: 'Draft',
		pathname: PathName.DRAFT_ASSESSMENTS,
		exact: true,
		is_menu_item: true,
		required_roles: [
			UserRole.OWNER,
			UserRole.ADMIN,
			UserRole.RECRUITER,
			UserRole.HIRING_MANAGER,
		],
		iconClass: <DraftAssessIcon />,
		component: AdminAssessments,
	},

	archived_assessments: {
		text: 'Archived',
		pathname: PathName.ARCHIVED_ASSESSMENTS,
		exact: true,
		is_menu_item: true,
		required_roles: [
			UserRole.OWNER,
			UserRole.ADMIN,
			UserRole.RECRUITER,
			UserRole.HIRING_MANAGER,
			UserRole.MEMBER,
		],
		iconClass: <ArchiveAssessIcon />,
		component: AdminAssessments,
	},

	get_current_assessment: {
		text: 'Assessment Details',
		pathname: `/:id`,
		exact: true,
		is_menu_item: false,
		required_roles: [
			UserRole.OWNER,
			UserRole.ADMIN,
			UserRole.RECRUITER,
			UserRole.HIRING_MANAGER,
			UserRole.CANDIDATE,
			UserRole.MEMBER,
		],
		component: () => <AssessmentDetailsPage />,
	},

	'publish-assessment': Object.freeze({
		text: 'Publish Assessment',
		pathname: '/publish/:id',
		exact: true,
		is_menu_item: false,
		required_roles: [UserRole.OWNER, UserRole.ADMIN, UserRole.RECRUITER],
		component: () => <PublishAssessment />,
	}),

	'assessment-criteria': Object.freeze({
		text: 'Assessment Criteria',
		pathname: '/criteria/:id',
		exact: true,
		is_menu_item: false,
		required_roles: [UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
		component: () => <AssessmentCriteria />,
	}),

	// get_current_assessment_candidate : {
	// 	text: 'Archived',
	// 	pathname: `/:id`,
	// 	exact: true,
	// 	is_menu_item: false,
	// 	required_roles: [UserRole.CANDIDATE],
	// 	iconClass: 'archived-rectangle-icon',
	// 	component: () => <AssessmentDetailsPage />
	// },

	// pending_assessments: {
	// 	text: 'Pending',
	// 	pathname: PathName.PENDING_ASSESSMENTS,
	// 	exact: true,
	// 	is_menu_item: true,
	// 	required_roles: [UserRole.CANDIDATE],
	// 	iconClass: 'archived-rectangle-icon',
	// 	component: () => <h3>Candidate Pending Assessments Page</h3>
	// }
};

export const CandidatesRoutes = {
	get_candidates: {
		text: 'list',
		pathname: PathName.CANDIDATESLIST,
		exact: true,
		is_menu_item: false,
		required_roles: [UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
		component: CandidateListingView,
	},

	get_specific_candidate: {
		text: '',
		pathname: `/:id`,
		exact: true,
		is_menu_item: false,
		required_roles: [UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
		component: CandidateDetails,
	},
};

export const TestHistoryRoutes = {

};

export const SettingsRoutes = {

	company_settings: {
		text: 'Company',
		pathname: PathName.COMPANY_SETTINGS,
		exact: true,
		is_menu_item: true,
		required_roles: [UserRole.OWNER, UserRole.ADMIN],
		iconClass: <CompanySettingIcon />,
		component: CompanySettings,
	},

	'/profile-settings': {
		text: 'Personal',
		pathname: PathName.USER_PROFILE_SETTINGS,
		exact: true,
		is_menu_item: false,
		has_nested_routes: false,
		nested_routes: [],
		required_roles: [
			UserRole.OWNER,
			UserRole.ADMIN,
			UserRole.CANDIDATE,
			UserRole.MEMBER,
		],
		iconClass: <UserSettingIcon />,
		component: () => <CompanySettings isForUserprofile />,
	},

	team_settings: {
		text: 'Team',
		pathname: PathName.TEAM_SETTINGS,
		exact: true,
		is_menu_item: true,
		required_roles: [UserRole.OWNER, UserRole.ADMIN, UserRole.RECRUITER],
		iconClass: <TeamSettingIcon />,
		component: TeamSettings,
	},

	billing_settings: {
		text: 'Billing',
		pathname: PathName.BILLING_SETTINGS,
		exact: true,
		is_menu_item: true,
		required_roles: [UserRole.OWNER],
		iconClass: <BillingSettingIcon />,
		component: BillingSettings,
	},

	api_access_token: Object.freeze({
		text: 'API',
		pathname: PathName.API_ACCESS_TOKEN,
		exact: true,
		is_menu_item: true,
		required_roles: [UserRole.OWNER],
		iconClass: <APIAccessTokenIcon />,
		component: APIAccessToken,
	}),
};

export const DashboardRoutes = {
	// '/candidate-dashboard': {
	// 	text: 'Dashboard',
	// 	pathname: '/dashboard',
	// 	exact: true,
	// 	is_menu_item: true,
	// 	has_nested_routes: false,
	// 	nested_routes: [],
	// 	required_roles: [UserRole.CANDIDATE],
	// 	iconClass: 'settings-icon-1',
	// 	plusIcon: 'plus-icon',
	// 	minusIcon: 'minus-icon',
	// 	component: CandidateDashboard
	// },

	assesments: {
		text: 'Assessments',
		pathname: PathName.ASSESSMENTS,
		exact: false,
		is_menu_item: true,
		has_nested_routes: true,
		nested_routes: AssessmentsRoutes,
		required_roles: [
			UserRole.OWNER,
			UserRole.ADMIN,
			UserRole.RECRUITER,
			UserRole.HIRING_MANAGER,
			UserRole.CANDIDATE,
			UserRole.MEMBER,
		],
		iconClass: <AssessmentIcon />,
		plusIcon: <PlusIcon />,
		minusIcon: <MinusIcon />,
		component: AssessmentPage,
	},

	candidates: Object.freeze({
		text: 'Candidates',
		pathname: PathName.CANDIDATES,
		exact: false,
		is_menu_item: true,
		has_nested_routes: true,
		nested_routes: CandidatesRoutes,
		required_roles: [UserRole.OWNER, UserRole.ADMIN, UserRole.MEMBER],
		iconClass: <CandidateIcon />,
		plusIcon: <PlusIcon />,
		minusIcon: <MinusIcon />,
		component: CandidatePage,
	}),

	'/candidate-test-history': Object.freeze({
		text: 'Test History',
		pathname: PathName.CANDIDATES_TEST_HISTORY,
		exact: false,
		is_menu_item: true,
		has_nested_routes: true,
		nested_routes: TestHistoryRoutes,
		required_roles: [UserRole.CANDIDATE,],
		iconClass: <TestHistoryIcon style={{ stroke: '#808191' }} />,
		plusIcon: <PlusIcon />,
		minusIcon: <MinusIcon />,
		component: CandidateTestHistory,
	}),

	settings: Object.freeze({
		text: 'Settings',
		pathname: PathName.SETTINGS,
		exact: false,
		is_menu_item: true,
		has_nested_routes: true,
		should_select_nested_item: true,
		nested_routes: SettingsRoutes,
		required_roles: [
			UserRole.OWNER,
			UserRole.ADMIN,
			UserRole.RECRUITER,
			UserRole.MEMBER,
		],
		// unvisible_for_roles: [UserRole.MEMBER],
		iconClass: <SettingsIcon />,
		plusIcon: <PlusIcon />,
		minusIcon: <MinusIcon />,
		component: Settings,
	}),

	'/candidate-profile-settings': {
		// text: 'Profile Settings',
		pathname: PathName.CANDIDATE_PROFILE_SETTINGS,
		exact: true,
		is_menu_item: false,
		has_nested_routes: false,
		nested_routes: [],
		required_roles: [UserRole.CANDIDATE],
		// iconClass: <SettingsIcon />,
		// plusIcon: <PlusIcon />,
		// minusIcon: <MinusIcon />,
		component: ProfileSettings,
	},

	// integrations: Object.freeze({
	// 	text: 'Integrations',
	// 	pathname: PathName.INTEGRATIONS,
	// 	exact: true,
	// 	is_menu_item: true,
	// 	required_roles: [UserRole.ADMIN],
	// 	iconClass: 'integrations-icon-1',
	// 	plusIcon: 'plus-icon',
	// 	minusIcon: 'minus-icon',
	// 	component: () => <h3>Integrations Page</h3>,
	// }),

	'create-assessment': Object.freeze({
		text: 'Create Assessment',
		pathname: `${PathName.ASSESSMENTS}/create/:id`,
		exact: true,
		is_menu_item: false,
		required_roles: [UserRole.OWNER, UserRole.ADMIN],
		component: AssessmentSpecsView,
	}),

	notifications: Object.freeze({
		text: 'Notifications',
		pathname: PathName.NOTIFICATIONS,
		exact: false,
		is_menu_item: true,
		has_nested_routes: false,
		required_roles: [
			UserRole.OWNER,
			UserRole.ADMIN,
			UserRole.MEMBER,
			UserRole.CANDIDATE,
		],
		iconClass: 'notification-icon',
		component: Notifications,
	}),


	// catch_all: {
	// 	text: 'Assessments',
	// 	pathname: '*',
	// 	exact: false,
	// 	is_menu_item: false,
	// 	has_nested_routes: false,
	// 	nested_routes: AssessmentsRoutes,
	// 	required_roles: [
	// 		UserRole.OWNER,
	// 		UserRole.ADMIN,
	// 		UserRole.RECRUITER,
	// 		UserRole.HIRING_MANAGER,
	// 		UserRole.CANDIDATE,
	// 		UserRole.MEMBER,
	// 	],
	// 	iconClass: <AssessmentIcon />,
	// 	plusIcon: <PlusIcon />,
	// 	minusIcon: <MinusIcon />,
	// 	component: AssessmentPage,
	// },
	// 'get-admin-assessment': Object.freeze({
	// 	text: 'Assessment',
	// 	pathname: `${PathName.ASSESSMENTS}/id=:id`,
	// 	exact: true,
	// 	is_menu_item: false,
	// 	required_roles: [UserRole.ADMIN],
	// 	// iconClass: 'integrations-icon-1',
	// 	hide_side_menu: true,
	// 	component: TestDetails,
	// }),

	// 'get-canididate-assessment': Object.freeze({
	// 	text: 'Assessment',
	// 	pathname: `${PathName.ASSESSMENTS}/id=:id`,
	// 	exact: true,
	// 	is_menu_item: false,
	// 	required_roles: [UserRole.CANDIDATE],
	// 	// iconClass: 'integrations-icon-1',
	// 	hide_side_menu: true,
	// 	component: CandidateAssessFlow,
	// }),

	// process_invite: Object.freeze({
	// 	text: 'Process Invite',
	// 	pathname: `${PathName.PROCESS_ASSESSMENT_INVITE}`,
	// 	exact: false,
	// 	is_menu_item: false,
	// 	required_roles: [UserRole.CANDIDATE],
	// 	// iconClass: 'integrations-icon-1',
	// 	hide_side_menu: false,
	// 	component: ProcessAssessmentInvite,
	// }),
};

// export const CandidateDashboardRoutes = {

// 	assesments: {
// 		text: 'Assessments',
// 		pathname: PathName.ASSESSMENTS,
// 		exact: true,
// 		is_menu_item: true,
// 		required_roles: [UserRole.ADMIN],
// 		iconClass: 'circle-check-icon',
// 		component: () => <h2>Candidate Dashboard</h2>
// 	},

// };
