import React, { useState, useEffect, useRef, useMemo } from 'react';

import { Modal, Box, useMediaQuery } from '@mui/material';
import { ReactComponent as CrossIcon } from '../../assets/icons/components/common/modal/cross.svg';
import { ReactComponent as CrossIcon1 } from '../../assets/icons/Views/dashboard/test-detail/x.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/components/common/modal/warning.svg';
import { ReactComponent as DangerIcon } from '../../assets/icons/components/common/modal/alert.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/components/common/modal/success.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/components/common/modal/copy.svg';
import { ReactComponent as CuationICon } from '../../assets/icons/onboarding/Cuation.svg';


import { ReactComponent as OpenAIIconLoading } from '../../assets/icons/Views/dashboard/assessments/openai-icon-loading.svg';


import { ReactComponent as UploadIcon } from '../../assets/icons/Arrows/upload-1.svg';
import Button from '../sub-component/Button';
import Select from '../sub-component/Select';
import { TestType } from '../../utils/Constants';
import ReactQuill from 'react-quill';
import TestpackDetails from '../dashboard/assessments/create-assessment/TestpackDetails';
import CustomSwitch from '../sub-component/Switch';
import Input from '../sub-component/Input';

export default function CustomModal(props) {
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');
	const {
		varrient,
		open,
		boxStyle,
		customClass,
		title,
		titleStyle,
		subTitle,
		description,
		warningAlert,
		dangerAlert,
		successAlert,
		dangerText,
		warningText,
		children,
		cancelBtnTitle,
		confirmBtnTitle,
		handleCloseModal,
		onConfirm,
		btnWidth,
		cancelBtnWidth,
		onClickToAddFile,
		fileUrl,
		fileUploadProcessing,
		fileUploadPercentage,
		fileUploadType,
		handleDragFile,
		isDragActive,
		isDragFailed,
		onDragToAddFile,
		isItemAdded,
		add_remove_assessment,
		error
	} = props;

	const [copied, setCopied] = useState(false);

	useEffect(() => {
		console.log(props?.test, '#props.test123');
	}, [props?.test]);


	if (varrient === 'candidate-side-modal') {
		return (
			<Modal
				open={open}
				//   onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='custom-modal'
			>
				<Box sx={boxStyle}>
					<div className='candidate-modal-container modal-container'>
						{/* <div className='cross-icon' onClick={handleCloseModal}><CrossIcon /></div> */}
						<div className='w-100 d-flex flex-column align-items-start' style={{ padding: '32px 32px 0px' }}>
							<div className='d-flex flex-column align-items-start'>
								<span className='headline-large semibold black-700'>{title}</span>
								{
									subTitle !== '' &&
									<span
										className='body-3'
										style={{
											marginTop: '8px',
											textAlign: 'center',
											color: '#6E6B7B',
										}}
									>
										{subTitle}
									</span>
								}
								{description && (
									<span
										className='body-3 black-500 '
										style={{ textAlign: 'left' }}
									>
										{description}
									</span>
								)}
							</div>
							{children}
						</div>
						<div className='modal-action-btn'>
							<Button
								btn='ragular-btn'
								varrient='secondary-btn-v2'
								title={cancelBtnTitle || 'Cancel'}
								style={{ width: btnWidth || '120px', height: '52px' }}
								onClick={handleCloseModal}
							/>
							<Button
								btn='ragular-btn'
								varrient='primary'
								title={confirmBtnTitle || 'Confirm'}
								style={{ width: btnWidth || '120px', height: '52px', marginLeft: '16px' }}
								onClick={onConfirm}
							/>
						</div>
					</div>
				</Box>
			</Modal>
		);
	}
	else if (varrient === 'candidate-side-assessment-result-modal') {
		return (
			<Modal
				open={open}
				onClose={() => { }}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='custom-modal'
			// sx={{ backgroundColor: 'rgba(33, 28, 65, 0.3)' }}
			>
				<div className='candidate-side-assessment-result-modal-container'>
					<div className='candidate-side-assessment-result-modal'>
						{children}
					</div>
				</div>
			</Modal>
		);
	} else if (varrient === 'admin-side-modal-without-btn') {
		return (
			<Modal
				open={open}
				//   onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='custom-modal'
			>
				<Box sx={boxStyle}>
					<div className='modal-container-without-btn'>
						<div className='cross-icon' onClick={handleCloseModal}>
							<CrossIcon />
						</div>
						<div className='d-flex flex-column align-items-center'>
							<span className='headline-large semibold black-700'>{title}</span>
							<span
								className='body-3'
								style={{
									marginTop: '8px',
									textAlign: 'center',
									color: '#6E6B7B',
								}}
							>
								{subTitle}
							</span>
						</div>
						{description && (
							<div
								className='d-flex w-100 justify-content-center'
								style={{ marginTop: '40px' }}
							>
								<span
									className='body-2 black-500 '
									style={{ textAlign: 'center' }}
								>
									{description}
								</span>
							</div>
						)}
						{children}
					</div>
				</Box>
			</Modal>
		);
	} else if (varrient === 'admin-side-image-upload') {
		return (
			<Modal
				open={open}
				//   onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='custom-modal'
			>
				<Box sx={boxStyle}>
					<div className='modal-container'>
						<div className='cross-icon' onClick={handleCloseModal}>
							<CrossIcon />
						</div>
						<div className='w-100 d-flex flex-column align-items-center' style={{ padding: '32px 32px 0px', }}>
							<div
								className='w-100 d-flex flex-column align-items-center'
								style={{
									background: '#F4F6F6',
									border: '2px dashed #3E454D',
									borderRadius: '8px',
									boxSizing: 'borderBox',
									cursor: 'pointer',
								}}
								onClick={onClickToAddFile}
								onDragEnter={handleDragFile}
								onDragLeave={handleDragFile}
								onDragOver={handleDragFile}
								onDrop={onDragToAddFile}
							>
								<div className='d-flex flex-column align-items-center pt-5 pb-5'>
									<span className='mb-4'>
										{fileUploadProcessing ? (
											`Uploading ${fileUploadPercentage}%`
										) : fileUrl ? (
											fileUploadType === 'image' ? (
												<img src={fileUrl} width='45' height='45' />
											) : (
												<video width='499' height='210' controls src={fileUrl} />
											)
										) : (
											<UploadIcon />
										)}
									</span>
									<span className='headline-large semibold black-700 mb-2'>
										{`${isDragActive
											? 'Drag here to upload'
											: 'Click to upload or drag and drop'
										}`}
									</span>
									<span className={`body-3`}>
										{`${fileUploadType === 'image'
											? 'jpg, jpeg, png or webp'
											: 'mp4, webm, ogg'
										} (max size ${fileUploadType === 'image' ? '5' : '100'}mb)`}
									</span>
								</div>
							</div>
							{
								error &&
								<span className='body-3 danger-text mt-2 text-center align-items-center w-100'>
									{error}
								</span>
							}
						</div>
						<div className='modal-action-btn'>
							<Button
								btn='ragular-btn'
								varrient='secondary-btn'
								title={cancelBtnTitle || 'Cancel'}
								style={{ width: btnWidth || '120px', height: '52px' }}
								onClick={handleCloseModal}
							/>
							<Button
								processing={fileUploadProcessing}
								btn='ragular-btn'
								varrient='primary'
								disabled={!fileUrl}
								title={confirmBtnTitle || 'Confirm'}
								style={{ width: btnWidth || '120px', height: '52px', marginLeft: '16px' }}
								onClick={onConfirm}
							/>
						</div>
					</div>
				</Box>
			</Modal>
		);
	}
	else if (varrient === 'view-test-details') {
		return (
			<Modal
				open={open}
				onClose={() => { }}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='view-test-details-modal'
			>
				<Box sx={boxStyle}>
					<TestpackDetails
						handleCloseModal={handleCloseModal}
						add_remove_assessment={add_remove_assessment}
						isItemAdded={isItemAdded}
						test={props.test}
						disabled={props.disabled}
						preview_questions={props.preview_questions}
						showPreviewPage={props.showPreviewPage}
					/>
				</Box>
			</Modal>
		);
	}
	else if (varrient === 'contact-support-for-search') {
		return (
			<Modal
				open={open}
				onClose={() => { }}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='contact-support-for-search'
			>
				<Box sx={boxStyle}>
					<div className='contact-support-for-search-container'>
						{/* <div className='cross-icon' onClick={handleCloseModal}><CrossIcon /></div> */}
						<div className='d-flex flex-column align-items-start' style={{ padding: '32px 32px 0px', gap: '24px' }}>
							<div className='contact-support-for-search-inner-container'>
								<span className='headline-medium semibold black-700'>Coming up empty-handed?</span>
								<Select readOnly
									options={[
										{ name: 'Skill Based Test', value: TestType.SKILL_TEST },
										{ name: 'Coding Test', value: TestType.CODING_TEST },
										{ name: 'Value Fitness Test', value: TestType.VALUE_TEST },
										{ name: 'Not Applicable', value: 'Not Applicable' }
									]}
									className="question-select-responsive"
									innerClassName="body-3"
									selected={props.test}
									onSelection={(option) => {
										props.getOption(option);
									}}
									title="" style={{ marginRight: '16px' }} />

							</div>
							<span className='body-1 black-700'>Don’t worry, provide our team with a detailed description of what you would like to include in our test bank.</span>
							<textarea value={props.value || ''} onChange={props.onChange} placeholder='Write something here...'></textarea>
							{
								props.error &&
								<span className='body-4'>{props.error}</span>
							}
						</div>
						<div className='modal-action-btn'>
							<Button
								btn='ragular-btn'
								size='lg'
								varrient='secondary-btn'
								title={cancelBtnTitle || 'Cancel'}
								style={{ width: btnWidth || '128px', height: '52px', ...props?.btnStyle }}
								onClick={handleCloseModal}
								rounded
							/>
							<Button
								processing={props.processing}
								btn='ragular-btn'

								size='lg'
								varrient='primary'
								title={confirmBtnTitle || 'Submit'}
								style={{ width: btnWidth || '128px', height: '52px', marginLeft: '16px', ...props?.btnStyle }}
								onClick={onConfirm}
								disabled={props?.onConfirmBtnDisable}
								rounded
							/>
						</div>
					</div>
				</Box>
			</Modal>
		);
	} else if (varrient === 'admin-jd-text-box') {
		const quillObjs = useRef([]);
		quillObjs.current = [];

		const addToRef = (el) => {
			if (el && !quillObjs.current.includes(el)) {
				quillObjs.current.push(el);
			}
		};

		const modules = useMemo(
			() => ({
				toolbar: {
					// handlers: {
					// 	image: () => {},
					// 	video: () => {},
					// },
					container: [
						[{ header: [1, 2, false] }],
						['bold', 'italic', 'underline', 'strike', 'blockquote'],
						[
							{ list: 'ordered' },
							{ list: 'bullet' },
							{ indent: '-1' },
							{ indent: '+1' },
						],
						['link'],
						['clean'],
					],
				},
				clipboard: {
					matchVisual: true,
				},
			}),
			[],
		);

		const formats = [
			'header',
			'font',
			'size',
			'bold',
			'italic',
			'underline',
			'strike',
			'blockquote',
			'list',
			'bullet',
			'indent',
			'link',
		];

		return (
			<Modal
				open={open}
				//   onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='custom-modal'
			>
				<Box sx={boxStyle}>
					<div className='modal-container-w-quill hide-scrollbar'>
						{
							props.loading
								?
								<div style={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', height: '25px', width: '250px', margin: 'auto' }}>
									<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
										<OpenAIIconLoading style={{ width: '50px', height: '50px' }} className="open-ai-icon" />
										{/* <p className='body-2' style={{ color: '#2B3340', marginTop: '20px' }}>{loadingMessages[currentMessageIndex]}</p> */}
									</div>
								</div>
								:
								<>
									<div className='modal-inner-container-w-quill'>
										<div className='d-flex flex-column align-items-start'>
											<span className='headline-3 dark-100' style={{ ...titleStyle }}>{title}</span>
											{
												subTitle &&
												<span
													className='body-2 dark-50'
													style={{
														marginTop: '12px',
													}}
												>
													{subTitle}
												</span>
											}

										</div>
										<div className='jd-quill-container'>
											<ReactQuill
												ref={addToRef}
												className={`jd-quill-inner-container`}
												theme='snow'
												modules={modules}
												formats={formats}
												value={props.content}
												onChange={(value) => {
													props.onChange(value === '<p><br></p>' ? '' : value);
												}}
												placeholder='Job Description here...'
											/>
										</div>
										{children}
									</div>
									<div className='modal-action-btn'>
										{
											props.hideScendBtn
												?
												null
												:
												<Button
													btn='ragular-btn'
													varrient='secondary-btn-v2'
													title={cancelBtnTitle || 'Cancel'}
													style={{ width: btnWidth || '120px', height: '52px', ...props?.btnStyle }}
													onClick={handleCloseModal}
													rounded
												/>
										}
										<Button
											processing={props.processing}
											btn='ragular-btn'
											varrient='primary'
											title={confirmBtnTitle || 'Confirm'}
											style={{ width: btnWidth || '120px', height: '52px', marginLeft: '16px', ...props?.btnStyle }}
											onClick={onConfirm}
											disabled={props?.onConfirmBtnDisable}
											rounded
										/>
									</div>
								</>
						}
					</div>
				</Box>
			</Modal>
		);
	} else if (varrient === 'hubspot-meeting-box') {
		return (
			<Modal
				open={open}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='custom-modal'
			>
				<Box sx={boxStyle}>
					<div className='hubspot-meeting-modal-container'>
						<div className='cross-icon' onClick={handleCloseModal}>
							<CrossIcon />
						</div>
						<div className='hubspot-meeting-modal-inner-container hide-scrollbar'>
							<iframe title='HubSpot Meetings' src={props.hubspotMeetingsLink} className='page' width='100%' height='700px' />
						</div>
					</div>
				</Box>
			</Modal>
		);

	} else if (varrient === 'expire-assessment-warning-modal') {
		return (
			<Modal
				open={open}
				//   onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='custom-modal'
			>
				<Box sx={boxStyle}>
					<div className='modal-container'>
						<div style={{ padding: '32px 32px 32px',display:'flex',flexDirection:'column',alignItems:'center',gap:'20px' }}>
							<div className='d-flex flex-column align-items-center' >
								{warningAlert ?
									<CuationICon style={{ width: '84px', height: '83px' }} />
									:
									dangerAlert ?
										<DangerIcon style={{ width: '100%', height: '100%' }} />
										:
										successAlert &&
											<SuccessIcon style={{ width: '100%', height: '100%' }} />
								}
							</div>
							<div className='d-flex flex-row align-items-start' style={{ gap: '16px' }}>
						
								<div className='d-flex flex-column align-items-center' style={{gap:'24px'}}>
									<span className='headline-large semibold black-700' style={{ ...titleStyle,color:'#6F2DBD' }}>{title}</span>
									{dangerText && (
										<span
											className='body-1 danger-text '
											style={{
												textAlign: 'flex-start'
											}}
										>
											{dangerText}
										</span>
									)}
									{description && (
										<span
											className='body-1 black-600 '
											style={{
												textAlign: 'center'
											}}
										>
											{description}
										</span>
									)}
									{subTitle && (
										<span
											className='body-2 black-600'
											style={{
												marginTop: '8px',
												textAlign: 'flex-start',
												// color: '#6E6B7B',
											}}
										>
											{subTitle}
										</span>
									)}
									{warningText && (
										<span
											className='body-1 danger-text '
											style={{
												textAlign: 'flex-start'
											}}
										>
											{warningText}
										</span>
									)}
								</div>
							</div>
							{children}
							<div>
								<Button
									processing={props.processing}
									title={confirmBtnTitle || 'Confirm'}
									style={{ width: btnWidth || '120px', height: '52px', marginLeft: '16px', backgroundColor:'#392D69',borderRadius:'50px',padding:'14px 32px', fontSize:'15px' }}
									onClick={onConfirm}
									disabled={props?.onConfirmBtnDisable || props.processing}
									
								/>
							</div>
						</div>
					

					</div>
				</Box>
			</Modal>
		);
	} else if (varrient === 'copy-result-link-modal') {
		return (
			<Modal
				open={open}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='custom-modal'
			>
				<Box sx={boxStyle}>
					<div className='modal-container'>
						<div className='w-100'  style={{padding:'24px'}}>
							<div className='w-100 d-flex flex-row align-items-start' style={{ gap: '16px' }}>
								<div className='w-100 d-flex flex-column' style={{ gap: '12px' }}>
									<div className='w-100 d-flex flex-row align-items-start justify-content-between'>
										<span className='headline-4 dark-100'>Share Assessment Result</span>
										<CrossIcon1 style={{height: '44px', width:'44px', cursor: 'pointer'}} onClick={handleCloseModal} />
									</div>
									<div className='w-100 d-flex flex-row align-items-start justify-content-between'>
										<span className='body-2 dark-50'>Make public</span>
										<div className='d-flex' style={{width: '40px'}}>
											<CustomSwitch
												checked={props.checked}
												onChange={props.handlePublicLinkState}
											/>
										</div>
									</div>
									<div className='w-100 d-flex flex-row align-items-end justify-content-between' style={{ gap: '10px', marginBottom: '15px' }}>
										<Input
											disabled={true}
											varrient='primary'
											className='body-3 primary-input'
											title={'link'}
											placeholder={'http:linkwillbeshownhere/'}
											value={props.inviteLink}
											style={{ maxWidth: 'calc(100% - 130px)' }}
										/>
										<Button
											btn='ragular-btn'
											varrient='primary'
											disabled={copied}
											title={copied ? 'Copied' : 'Copy Link'}
											style={{ width: '120px' }}
											onClick={() => {
												navigator.clipboard.writeText(props.inviteLink);
												setCopied(true);
												setTimeout(() => setCopied(false), 1000);
											}}
										/>
									</div>
								</div>
							</div>
							{children}
						</div>
					</div>
				</Box>
			</Modal>
		);
	} else if (varrient === 'generate-token-modal') {
		return (
			<Modal
				open={open}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='custom-modal'
			>
				<Box sx={boxStyle}>
					<div className='modal-container'>
						<div className='w-100 d-flex flex-column align-items-start' style={{ gap: '16px' }}>
							<div className='w-100 d-flex flex-column' style={{ padding:'24px', gap: '12px' }}>
								<span className='headline-4 dark-100 text-center'>New API access token generated</span>
								<span className='body-2 dark-50 text-center'>Your access token to the Testfuse API has been generated. Please copy the string below and store it for your records. You will not be able to see this token after you close this modal.</span>
								<div className='copy-input-container'>
									<div className='copy-input'>
										<Input
											disabled={true}
											className={'w-100 body-3'}
											inputStyle={{background: 'transparent', border: 'none'}}
											placeholder={'s8g8DTxMdvIaqu9JRpjSW8x_9vGeSL95gIPdiIgDvjM'}
											value={props.tokenValue}
										/>	
										<CopyIcon 
											height={'20px'}
											width={'20px'}
											style={{cursor: 'pointer'}}
											onClick={() => {
												navigator.clipboard.writeText(props.tokenValue);
												setCopied(true);
												props.setAlert();
												setTimeout(() => setCopied(false), 1000);
											}}
										/>
									</div>
								</div>
								<span className='body-2 dark-50 text-center'>Important: This access token will not be visible after you close this modal. You will need to generate a new token if you need to view it again.</span>
							</div>
							<div className='modal-action-btn justify-content-end'>
								<Button
									btn='ragular-btn'
									varrient='secondary-btn-v2'
									style={{ width:'120px', height: '52px', ...props?.btnStyle }}
									title={'Close'}
									onClick={handleCloseModal}
									disabled={props.processing}
								/>
							</div>
						</div>
						{children}
					</div>
				</Box>
			</Modal>
		);
	} else if (varrient === 'alert-warning-modal') {
		return (
			<Modal
				open={open}
				//   onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='custom-modal'
			>
				<Box sx={boxStyle}>
					<div className='modal-container'>
						<div  style={{padding:'24px'}}>
							<div className='d-flex flex-row align-items- center' style={{ gap: '16px' }}>
								{/* <div className='d-flex flex-column align-items-start' style={{ width: isOnMobileScreen ? '48px' : '64px', height: isOnMobileScreen? '48px' : '64px' }}>
									{warningAlert ?
										<WarningIcon style={{ width: '100%', height: '100%' }} />
										:
										dangerAlert ?
											<DangerIcon style={{ width: '100%', height: '100%' }} />
											:
											successAlert &&
											<SuccessIcon style={{ width: '100%', height: '100%' }} />
									}
								</div> */}
								<div className='d-flex flex-column ' style={{ gap: '12px' }}>
									<span className='headline-4 dark-100' style={{ ...titleStyle }}>{title}</span>
									{dangerText && (
										<span
											className='body-1 danger-text '
											style={{
												textAlign: 'start'
											}}
										>
											{dangerText}
										</span>
									)}
									{description && (
										<span
											className='body-1 dark-100 '
											style={{
												textAlign: 'start',
											}}
										>
											{description}
										</span>
									)}
									{subTitle && (
										<span
											className='body-2 dark-100'
											style={{
												marginTop: '8px',
												textAlign: 'center',
												// color: '#6E6B7B',
											}}
										>
											{subTitle}
										</span>
									)}
									{warningText && (
										<span
											className='body-1 danger-text '
											style={{
												textAlign: 'flex-start'
											}}
										>
											{warningText}
										</span>
									)}
								</div>
							</div>
							{children}
						</div>
						{
							!props.hideBtns &&
							<div className='modal-action-btn'>
								{
									props.hideScendBtn
										?
										null
										:
										<Button
											btn='ragular-btn'
											varrient='secondary-btn-v2'
											title={cancelBtnTitle || 'Cancel'}
											style={{ width: cancelBtnWidth || '120px', height: '52px', ...props?.btnStyle }}
											onClick={handleCloseModal}
											disabled={props.processing}
											rounded
										/>
								}
								<Button
									processing={props.processing}
									btn='ragular-btn'
									varrient='primary'
									title={confirmBtnTitle || 'Confirm'}
									style={{ width: btnWidth || '120px', height: '52px', marginLeft: '16px', ...props?.btnStyle }}
									onClick={onConfirm}
									disabled={props?.onConfirmBtnDisable || props.processing}
									rounded
								/>
							</div>
						}

					</div>
				</Box>
			</Modal>
		);
	} else {
		return (
			<Modal
				open={open}
				//   onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
				className='custom-modal'
			>
				<Box sx={boxStyle}>
					<div className={`modal-container ${customClass}`}>
						<div className='d-flex flex-column align-items-start' style={{ padding: '24px', gap: '12px' }}>
							<span className='headline-4 dark-100' style={{ ...titleStyle }}>{title}</span>
							{subTitle && (
								<span
									className='body-2 dark-50'
									style={{
										marginTop: '8px',
										textAlign: 'flex-start',
										// color: '#6E6B7B',
									}}
								>
									{subTitle}
								</span>
							)}
							{description && (
								<span
									className='body-1 black-600 '
									style={{
										textAlign: 'flex-start'
									}}
								>
									{description}
								</span>
							)}
							{children}
						</div>
						{
							!props.hideBtns &&
							<div className='modal-action-btn'>
								{
									props.hideScendBtn
										?
										null
										:
										<Button
											btn='ragular-btn'
											varrient='secondary-btn-v2'
											title={cancelBtnTitle || 'Cancel'}
											style={{ width: btnWidth || '120px', height: '52px', ...props?.btnStyle }}
											onClick={handleCloseModal}
											disabled={props.processing}
											rounded
										/>
								}
								<Button
									processing={props.processing}
									btn='ragular-btn'
									varrient='primary'
									title={confirmBtnTitle || 'Confirm'}
									style={{ width: btnWidth || '120px', height: '52px', marginLeft: '16px', ...props?.btnStyle }}
									onClick={onConfirm}
									disabled={props?.onConfirmBtnDisable || props.processing}
									rounded
								/>
							</div>
						}

					</div>
				</Box>
			</Modal>
		);
	}
}
