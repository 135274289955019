import React from 'react';

const Checkbox = (props) => {
	const { primary, primary_light, onClick, checked, onChange, id, disabled, processing, value, style, className, title, titleStyle, description, descriptionStyle } = props;

	if (primary) {
		return (
			<div className='primary-checkbox' onClick={onClick}>
				<div className='primary-checkbox-container'>
					<input type='checkbox'
						readOnly={true}
						checked={checked}
						onChange={onChange}
						id={id}
						style={{ ...style }}
						disabled={disabled || processing}
						value={value}
						className={`${className} primary-checkbox-input pointer`} />
				</div>
				<div className='w-100 d-flex flex-column' style={{gap: '4px'}}>
					<span className='ms-2 body-3' style={titleStyle}>{title}</span>
					{description &&
						<span className='ms-2 body-3' style={descriptionStyle}>{description}</span>
					}
				</div>
			</div>

		);
	}
	if (primary_light) {
		return (
			<div className='primary-light-checkbox' onClick={onClick}>
				<div className='primary-light-checkbox-container'>
					<input type='checkbox'
						readOnly={true}
						checked={checked}
						onChange={onChange}
						id={id}
						style={{ ...style }}
						disabled={disabled || processing}
						value={value}
						className={`${className} primary-light-checkbox-input pointer`} />
				</div>
				<span className='primary-light-checkbox-title ms-2 description' style={titleStyle}>
					{title}
				</span>
			</div>
		);
	}
};

export default Checkbox;