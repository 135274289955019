import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DateToDDMMYYYY } from '../../utils/utilities';
import CustomModal from '../../components/common/Modal';
import Input from '../../components/sub-component/Input';
import { useFocusWithin } from '@react-aria/interactions';
import { ReactComponent as MenuIcon } from '../../assets/icons/basic/DotsThree.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/basic/danger.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/Views/candidate/assessments/clock.svg';

export default function APIAccessTokenCard(props) {
	const { data, revokeAccessToken } = props;
	const [openMenu, setOpenMenu] = useState(false);
	const [isExpired, setIsExpired] = useState(false);
	const [openRevokeTokenModal, setOpenRevokeTokenModal] = useState(false);
	const { userProfileData } = useSelector((state) => state.userProfileReducer);
	const USER_NAME = `${userProfileData?.first_name} ${userProfileData?.last_name}`;

	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: (e) => { },
		onBlurWithin: (e) => {
			setOpenMenu(false);
		}
	});

	useEffect(() => {
		const checkExpiry = () => {
			const currentDate = new Date();
			const expiry = new Date(data?.expires_at);

			if (currentDate >= expiry) {
				setIsExpired(true);
			}
		};

		checkExpiry();
		const interval = setInterval(checkExpiry, 60000);

		return () => clearInterval(interval);
	}, [data?.expires_at]);

	return (
		<>
			<CustomModal
				open={openRevokeTokenModal}
				varrient='alert-warning-modal'
				title={`Revoke API token?`}
				description={`If you revoke this token, every script and application that accesses Testfuse's API using this token will stop functioning. You will need to generate a new token and update every script and application to continue accessing Testfuse's API.`}
				handleCloseModal={() => {setOpenRevokeTokenModal(false);}}
				btnWidth={'max-content'}
				onConfirm={() => {revokeAccessToken();}}
			/>
			<div className={`api-access-token-card-container`}>
				<div className='api-access-token-card-header' tabIndex={-1} {...focusWithinProps}>
					<span className='body-1 dark-100'>{data?.name}</span>
					<MenuIcon className='pointer' onClick={() => setOpenMenu(!openMenu)}/>
					{openMenu &&
						<div className='menu-container'>
							<div className='menu-card' onClick={() => {setOpenRevokeTokenModal(true);}}>
								<span className='body-1 dark-100'>Revoke</span>
							</div>
						</div>
					}
				</div>
				<span className='body-2 dark-50 text-start'>{data?.permissions?.map((p, i) => {return (<span key={i}>{`${p.id} ${i === data?.permissions?.length - 1 ? '' : ', '}`}</span>);})}</span>
				<Input
					readOnly
					varrient='primary'
					inputStyle={{
						width: '100%',
						background: 'white',
						border: 'none',
					}}
					className='body-2'
					placeholder='s8g***********************************************************vjM'
					value={`${data?.prefix}***********************************************************${data?.suffix}`}
				/>
				{isExpired ?
					<div className='w-100 d-flex flex-row align-items-center' style={{ gap: '8px' }}>
						<WarningIcon style={{width: '20', height: '20'}} />
						<span className='body-2 danger-text'>{`Expired at ${DateToDDMMYYYY(data?.expires_at)}`}</span>
					</div>
					:
					<div className='w-100 d-flex flex-row align-items-center' style={{ gap: '8px' }}>
						<ClockIcon />
						<span className='body-2 dark-50'>{`Expires at ${DateToDDMMYYYY(data?.expires_at)}`}</span>
					</div>
				}
				<span className='body-2 dark-50 text-start'>Created by <span className='semibold dark-100'>{USER_NAME}</span>{` on ${DateToDDMMYYYY(data?.creator?.created_at)}`}</span>
			</div>
		</>
	);
}
