import React, { useState, useEffect, useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RequestStatus, } from '../../utils/Constants';
import {calculateTimeDifferenceInDays} from '../../utils/utilities';
import APIAccessTokenCard from './APIAccessTokenCard';
import AlertBox from '../../components/common/AlertBox';
import CustomModal from '../../components/common/Modal';
import Input from '../../components/sub-component/Input';
import Select from '../../components/sub-component/Select';
import Button from '../../components/sub-component/Button';
import Checkbox from '../../components/sub-component/Checkbox';
import ProcessingIndicator from '../../components/common/ProcessingIndicator';
import { ReactComponent as ArrowIcon } from './../../assets/icons/Arrows/PCaretRight.svg';
import { ReactComponent as PlusIcon } from './../../assets/icons/Views/dashboard/assessments/plus.svg';
import { ReactComponent as IllustrationIcon } from './../../assets/images/dashboard/Api-Illustration.svg';
import { GetAPIAccessToken, CreateAPIAccessToken, RevokeAPIAccessToken, GetAPITokenPermissions } from '../../redux/thunks/APIAccessToken';

export default function APIAccessToken() {
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	const expiryOptions = [
		{ name: '30 days', value: '30 days' },
		{ name: '90 days', value: '90 days' },
		{ name: '6 months', value: '6 months' },
		{ name: '1 year', value: '1 year' },
		{ name: '2 years', value: '2 years' },
	];

	const dispatch = useDispatch();
	const [alert, setAlert] = useState(null);
	const [testData, setTestData] = useState([]);
	const [createTokenModal, setCreateTokenModal] = useState(false);
	const [generateTokenModal, setGenerateTokenModal] = useState(false);
	const [accessTokenData, setAccessTokenData] = useState({
		name: '',
		expires_at: null,
		scopes: [],
	});

	const apiAccessToken = useSelector((state) => state.apiAccessTokenReducer);
	const auth = useSelector((state) => state.authReducer);
	const subscription = useSelector((state) => state.subscriptionReducer);
	const expireAt = subscription?.user_subscription.length > 0 ? subscription?.user_subscription[0]?.expires_at : null;
	const daysLeft = calculateTimeDifferenceInDays(expireAt);
	const openLeftDaysDisclaimer = sessionStorage.getItem('openLeftDaysDisclaimer');

	const isAccessTokenDataEmpty = (data) => {
		return Object.values(data).some((value) => {
			return value === null || value === '' || (Array.isArray(value) && value.length === 0);
		});
	};
	const isEmpty = isAccessTokenDataEmpty(accessTokenData);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setAccessTokenData({ ...accessTokenData, [name]: value });
	};

	const handleScopeChange = (event) => {
		const { value, checked } = event.target;

		setAccessTokenData((prevData) => {
			const updatedScopes = checked
				? [...prevData.scopes, value]
				: prevData.scopes.filter((type) => type !== value);

			return {
				...prevData,
				scopes: updatedScopes,
			};
		});
	};

	const handleCreateAssessSpec = async () => {
		const { payload, meta } = await dispatch(CreateAPIAccessToken({ data: accessTokenData }));
		if (meta?.requestStatus === RequestStatus.REJECTED) {
			setAlert(<AlertBox
				color='danger'
				description={payload}
				title='Error'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
		} else if (meta?.requestStatus === RequestStatus.FULFILLED) {
			setAlert(<AlertBox
				color='success'
				description={'API Access Token has been created.'}
				title='Success'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
			await dispatch(GetAPIAccessToken());
			setGenerateTokenModal(true);
		}
		setCreateTokenModal(false);
		setAccessTokenData({
			name: '',
			expires_at: null,
			scopes: [],
		});
	};

	const revokeAccessToken = async (id) => {
		const { payload, meta } = await dispatch(RevokeAPIAccessToken({ id: id }));
		if (meta?.requestStatus === RequestStatus.REJECTED) {
			setAlert(<AlertBox
				color='danger'
				description={payload}
				title='Error'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
		} else if (meta?.requestStatus === RequestStatus.FULFILLED) {
			setAlert(<AlertBox
				color='success'
				description={'API Access Token has been updated.'}
				title='Success'
				isOpen={true}
				onCloseAlert={() => { setAlert(null); }}
			/>);
			await dispatch(GetAPIAccessToken());
		}
	};

	useEffect(() => {
		dispatch(GetAPIAccessToken());
	}, []);

	useEffect(() => {
		dispatch(GetAPITokenPermissions());
	}, []);

	useMemo(() => {
		if (apiAccessToken?.data === null) return;
		setTestData(apiAccessToken?.data);
	}, [apiAccessToken?.data]);

	const defaultAPITokenCard = () => {
		return (
			<>
				<div className='w-100 d-flex flex-column align-items-center justify-content-center' style={{ gap: '16px' }}>
					<IllustrationIcon />
					<span className='subtitle-2 dark-100'>{`Generate a token to access Testfuse API`}</span>
					<span className='body-2 dark-50'>{`This gives you access to your assessment records and the ability to invite candidates`}</span>
					<a href={'https://api.testfuse.com/'} style={{textDecoration: 'none'}} rel='noopener noreferrer' target='_blank'>
						<div className='w-100 d-flex flex-row align-items-center justify-content-center pointer' style={{ gap: '8px' }}>
							<span className='body-1 primary-text'>{`Read more about API tokens`}</span>
							<ArrowIcon />
						</div>
					</a>
					<div className='d-flex align-items-center justify-content-center' style={{ height: '52px', gap: '16px' }}>
						<Button
							preIcon={<PlusIcon color='white' />}
							varrient='primary'
							btn='ragular-btn'
							title={' Generate API Tokens'}
							onClick={() => {setCreateTokenModal(true);}}
						/>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			{alert}

			<CustomModal
				open={createTokenModal}
				confirmBtnTitle={'Generate Token'}
				processing={apiAccessToken?.processing}
				onConfirmBtnDisable={isEmpty}
				btnWidth={'max-content'}
				onConfirm={handleCreateAssessSpec}
				handleCloseModal={() => {setCreateTokenModal(false);}}
			>
				<div className='w-100 d-flex flex-column' style={{ gap: '24px' }}>
					<span className='headline-4 dark-100 text-center'>Generate API Token</span>
					<div className='w-100 d-flex flex-column' style={{ gap: '12px' }}>
						<Input
							name='name'
							title={'Name'}
							varrient={'primary'}
							placeholder='Type a Name'
							value={accessTokenData?.name}
							onChange={(e) => handleChange(e)}
						/>
						<Select 
							readOnly 
							varrient='primary' 
							title={'Expires in'}
							placeholder={'Select Year'} 
							options={expiryOptions}
							selected={accessTokenData?.expires_at}
							onSelection={(option) => {
								setAccessTokenData({ ...accessTokenData, expires_at: option.value });
							}}
						/>
					</div>
					<div className='w-100 d-flex flex-column' style={{ gap: '12px' }}>
						<span className='body-2 dark-100'>API Scopes</span>
						<span className='body-2 dark-50'>Choose what you’d like to do with access tokens</span>
					</div>
					<div className='w-100 mb-2 d-flex flex-column' style={{ gap: '12px' }}>
						{apiAccessToken?.api_token_permissions?.map((p, i) => {
							return (
								<Checkbox
									key={i}
									primary
									title={p?.id}
									value={p?.id}
									description={p?.description}
									titleStyle={{ fontSize: '14px', color: '#808191' }}
									descriptionStyle={{ fontSize: '14px', color: '#808191' }}
									checked={accessTokenData?.scopes?.includes(p?.id)}
									onChange={handleScopeChange}
								/>
							);
						})}
					</div>
				</div>
			</CustomModal>

			<CustomModal
				open={generateTokenModal}
				varrient='generate-token-modal'
				setAlert={() =>
					setAlert(<AlertBox
						color='success'
						description={'API Access Token copied successfully.'}
						title='Success'
						isOpen={true}
						onCloseAlert={() => { setAlert(null); }}
					/>)
				}
				tokenValue={apiAccessToken?.current_api_access_token?.keyValue}
				handleCloseModal={() => {setGenerateTokenModal(false);}}
			/>

			<div className='api-access-token-main-container'>
				<div className='dashboard-page hide-scrollbar' style={{ overflow: 'hidden' }}>
					<div className=' d-flex align-items-center justify-content-between' style={{ padding: '0px 0px 24px', width: '99%', margin: 'auto' }}>
						<span className='headline-3 dark-100'>{`API Access Token`}</span>
						{isOnMobileScreen ?
							<div className='d-flex' style={{ height: '52px', marginTop: '16px', gap: '16px' }}>
								<div
									className='d-flex align-items-center justify-content-center pointer'
									style={{
										height: '44px',
										width: '44px',
										background: '#121216',
										borderRadius: '50px',
									}}
									onClick={() => {setCreateTokenModal(true);}}
								>
									<PlusIcon color='white' stroke='white' />
								</div>
							</div>
							:
							<div className='d-flex' style={{ height: '52px', marginTop: '16px', gap: '16px' }}>
								<Button
									preIcon={<PlusIcon color='white' />}
									varrient='primary'
									btn='ragular-btn'
									title={' Generate API Tokens'}
									onClick={() => {setCreateTokenModal(true);}}
								/>
							</div>
						}
					</div>
					<div
						className={`${daysLeft < 6 && auth?.data?.tenant_status === 'trialing' && !openLeftDaysDisclaimer && 'left-day-message-main-box'} page-content hide-scrollbar`}
						style={{ overflowY: 'auto' }}
					>
						<div className='api-access-token-container hide-scrollbar'>
							{apiAccessToken?.processing ?
								<ProcessingIndicator />
								:
								<>
									{(testData === null || !testData?.length) ?
										defaultAPITokenCard()
										:
										<div className='w-100 d-flex flex-column align-items-start' style={{gap: '16px'}}>
											<div className='w-100 d-flex flex-column align-items-start' style={{gap: '12px'}}>
												<span className='body-2 dark-50 text-start'>Generate a token to access Testfuse API. This gives you access to your assessment records and the ability to invite candidates</span>
												<a href={'https://api.testfuse.com/'} style={{textDecoration: 'none'}} rel='noopener noreferrer' target='_blank'>
													<div className='w-100 d-flex flex-row align-items-start pointer' style={{ gap: '8px' }}>
														<span className='body-1 primary-text'>{`Read more about API tokens`}</span>
														<ArrowIcon />
													</div>
												</a>
											</div>
											{testData?.map((data, index) => {
												return (
													<APIAccessTokenCard
														key={index}
														data={data}
														revokeAccessToken={() => {revokeAccessToken(data?.id);}}
													/>
												);
											})}
										</div>
									}
								</>
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

