import React from 'react';
import Plan from '../components/auth/Plan';
import OnboardingBox from '../components/OnboardingBox';
import Button from '../components/sub-component/Button';
import { useMediaQuery } from '@mui/material';
import { refreshToken } from '../redux/thunks/Auth';
import { removeAllCookies } from '../utils/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { createSubscription } from '../redux/thunks/Subscription';

export default function PlanPage() {
	const isOnTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1279px)');
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	const dispatch = useDispatch();
	const auth = useSelector((state) => state.authReducer);
	const subscription = useSelector((state) => state.subscriptionReducer);

	const handleConfirmPlan = async (plan) => {
		await dispatch(createSubscription({ stripe_product_id: plan?.id, interval: plan?.interval }));
		await dispatch(refreshToken());
	};

	if (auth.data.role.name === 'Admin' || auth.data.role.name === 'Member') {
		return (
			<>
				<OnboardingBox>
					<div className='d-flex flex-column signup-form-container'>
						<div className='form'>
							<div className={`heading ${isOnMobileScreen || isOnTabletScreen ? 'hero-small' : 'headline-medium'} bold`}>
								Onboarding Pending
							</div>
							<div className={`body-${isOnMobileScreen || isOnTabletScreen ? '1' : '3'} light`} style={{ marginTop: '10px' }}>
								Please reach out to the owner of this account to complete the onboarding for this organization.
							</div>
							<div className='submit-button' style={{ marginTop: '32px' }}>
								<Button
									rectangle-button
									title={'Sign out'}
									style={{ height: '48px' }}
									onClick={() => { removeAllCookies(); }}
								/>
							</div>
						</div>
					</div>
				</OnboardingBox>
			</>
		);
	}

	return (
		<>
		
			<OnboardingBox enableFullPage={true} varrient={'primary'}>
				<Plan onConfirmPlan={handleConfirmPlan} tenant_status={auth.data.tenant_status} processing={subscription?.is_loading} />
			</OnboardingBox>
			
		</>
	);
}
