import React, { useState, useEffect, useRef } from 'react';
// import { Fragment } from 'react';
// import ReactRoundedImage from 'react-rounded-image';

import { ReactComponent as ArrowDown } from '../../assets/icons/components/common/table/select.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/components/common/table/x.svg';
// import Tag from './Tag';
import { useFocusWithin } from '@react-aria/interactions';
import { useMediaQuery } from '@mui/material';

/**
 * Select Component
 * @param {object} props Form Select Component
 * @param {Function} updateSelections Update Selected States which Send back the selected option To The Parent(or where you call it)
 * @param {String} varient should be either 'multi-select' or null (for single selection)
 * @param {Array} options array of string (options)
 * @param {Array} selected array of selected option. Pass it an array in which you want to save options
 * [IMPORTANT] *********** This Component accept only options as object array with three attributes label, value and image. Image attribute is optional.
*/

export default function Select(props) {

	const { varrient, selectIcon, title, isRequired, processing, options, titleStyle, style, className, readOnly, placeholder, innerStyle, innerClassName, inputStyle, onSelection, onAddOption } = props;
	const isOnMobileScreen = useMediaQuery('(max-width: 767px)');

	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState(null);
	const [inputValue, setInputValue] = useState('');

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
		setIsOpen(true);
	};

	const handleSelect = (option) => {
		setSelected(option);
		setInputValue(option.name);
		onSelection(option);
		setIsOpen(false);
	};

	const addCustomOption = () => {
		const customOption = { name: inputValue, value: inputValue };
		onAddOption(customOption);
		setSelected(customOption);
		setInputValue(customOption.name);
		onSelection(customOption);
		setIsOpen(false);
	};

	const handleClearInput = () => {
		setInputValue('');
		setIsOpen(true);
		setSelected(null);
		onSelection(null);
	};

	const selectRef = useRef();

	const optionSelected = (option) => {
		if (props?.register?.name) {
			props.setValue(props.register.name, option.name);
			props.clearErrors(props.register.name);
		}
		setSelected(option);
		onSelection(option);
		setIsOpen(false);
	};

	const { focusWithinProps } = useFocusWithin({
		onFocusWithin: (e) => { },
		onBlurWithin: (e) => {
			setIsOpen(false);
		}
	});

	useEffect(() => {
		if (props.selected && options.length > 0) {
			const _arr = options.filter((option) => (option.value === props.selected || option.name === props.selected));
			if (_arr.length > 0) {
				setSelected(_arr[0]);
			}
			else {
				// setSelected(null);
				const customOption = { name: props.selected, value: props.selected };
				setSelected(customOption);
			}
		}
	}, [props.selected]);

	if (varrient === 'select-with-search') {
		return (
			<div title={props.toolTip} className={`d-flex align-items-center ${className}`} style={{ width: '100%', ...style }} tabIndex={-1} {...focusWithinProps}>
				{
					title &&
					<div style={{ minWidth: 'fit-content', marginRight: '16px' }}>
						<span style={{ ...titleStyle }} className='mb-2 body-3'>{title}</span>
					</div>
				}
				<div className='select-container' style={{ ...innerStyle }}>
					<input
						readOnly={readOnly}
						ref={selectRef}
						className={innerClassName}
						style={{ cursor: props.disabled ? 'not-allowed' : 'pointer', ...inputStyle }}
						disabled={props.disabled}
						placeholder={placeholder ? placeholder : 'Choose something'}
						value={selected?.name || inputValue}
						onChange={handleInputChange}
						onFocus={handleClearInput}
					/>
					{selected || inputValue ?
						<CrossIcon className='arrow-icon' />
						:
						<ArrowDown className='arrow-icon' />
					}

					<>
						{
							isOpen &&
							<>
								{
									processing
										?
										<div className='loader-container'>
											<span className='processing-indicator-wrapper small-indicator'>
												<span className='processing-indicator grey' />
											</span>
										</div>
										:
										<div className='menu-items-container hide-scrollbar' style={{ ...props.itemContainerStyle }}>
											{options
												.filter((option) =>
													option.name.toLowerCase().includes(inputValue.toLowerCase())
												)
												.map((option, i) => (
													<div
														key={i}
														className='menu-item-container'
														onClick={(e) => {
															e.stopPropagation();
															handleSelect(option);
														}}
													>
														<span style={{ textAlign: 'start' }}>{option.name}</span>
													</div>
												))}
											{inputValue &&
												!options.some(
													(option) =>
														option.name.toLowerCase() === inputValue.toLowerCase()
												) && <div className='menu-item-container' onClick={addCustomOption}><div className='body-4 semibold primary-text' style={{ marginRight: '12px' }}>{'+ Add'}</div>{inputValue}</div>
											}
										</div>
								}
							</>
						}
					</>

				</div>

			</div>
		);
	} else if (varrient === 'select-with-mbl-icon') {
		return (
			<div title={props.toolTip} className={`d-flex flex-column align-items-start ${className}`} style={{ width: '100%', ...style }} tabIndex={-1} {...focusWithinProps}>
				{
					title &&
					<div style={{ minWidth: 'fit-content', paddingLeft: '24px' }}>
						<span style={{ ...titleStyle }} className='mb-2 body-2 dark-50'>{title}</span>
					</div>
				}
				<div className='select-container' style={{ ...innerStyle }}>
					{(isOnMobileScreen && selectIcon) ?
						<div
							onClick={(e) => {
								e.stopPropagation();
								setIsOpen(!isOpen);
							}}
						>
							{selectIcon}
						</div>
						:
						<>
							<input readOnly={readOnly} ref={selectRef}
								className={innerClassName}
								style={{ cursor: props.disabled ? 'not-allowed' : 'pointer', ...inputStyle }}
								value={selected?.name}
								disabled={props.disabled}
								placeholder={placeholder ? placeholder : 'Choose something'}
								onClick={(e) => {
									e.stopPropagation();
									setIsOpen(!isOpen);
								}}
								onChange={(e) => { setSelected(e.target.value); }}
								{...props.register}
							/>
							<ArrowDown className="arrow-icon" />
						</>
					}
					<>
						{
							isOpen &&
							<>
								{
									processing
										?
										<div className='loader-container'>
											<span className='processing-indicator-wrapper small-indicator'>
												<span className='processing-indicator grey' />
											</span>
										</div>
										:
										<div className='menu-items-container' style={{ ...props.itemContainerStyle }}>
											{
												options.map((option, i) => {
													return (
														<div
															key={i}
															className="menu-item-container"
															onClick={(e) => {
																e.stopPropagation();
																optionSelected(option);
															}}
														>
															<span style={{ textAlign: 'start' }}>{option.name}</span>
														</div>
													);
												})
											}

										</div>
								}
							</>
						}
					</>
				</div>
			</div>
		);
	} else {
		return (
			<div title={props.toolTip} className={`d-flex flex-column align-items-start ${className}`} style={{ width: '100%', ...style }} tabIndex={-1} {...focusWithinProps}>
				{
					title &&
					<div className='mb-2' style={{ minWidth: 'fit-content', paddingLeft: '24px' }}>
						<span style={{ ...titleStyle }} className='body-2 dark-50'>
							{title}
							{isRequired &&
								<span className='danger-text'>*</span>
							}
						</span>
					</div>
				}
				<div className='select-container' style={{ ...innerStyle }}>
					<input readOnly={readOnly} ref={selectRef}
						className={innerClassName}
						style={{ cursor: props.disabled ? 'not-allowed' : 'pointer', ...inputStyle }}
						value={selected?.name}
						disabled={props.disabled}
						placeholder={placeholder ? placeholder : 'Choose something'}
						onClick={(e) => {
							e.stopPropagation();
							setIsOpen(!isOpen);
						}}
						// onBlur={() => { setIsOpen(false); }}
						onChange={(e) => { setSelected(e.target.value); }}
						{...props.register}
					/>
					<ArrowDown className="arrow-icon" />

					<>
						{
							isOpen &&
							<>
								{
									processing
										?
										<div className='loader-container'>
											<span className='processing-indicator-wrapper small-indicator'>
												<span className='processing-indicator grey' />
											</span>
										</div>

										:
										<div className='menu-items-container' style={{ ...props.itemContainerStyle }}>
											{
												options.map((option, i) => {
													return (
														<div
															key={i}
															className="menu-item-container"
															onClick={(e) => {
																e.stopPropagation();
																optionSelected(option);
															}}
														>
															<span style={{ textAlign: 'start' }}>{option.name}</span>
														</div>
													);
												})
											}

										</div>
								}
							</>
						}
					</>

				</div>

			</div>
			// <div
			// 	className="d-flex flex-column w-100 align-items-start position-relative pointer"
			// 	style={{ ...props.style }}
			// 	tabIndex="0"
			// 	onBlur={() => { setIsOpen(false); }}
			// 	onClick={props.disable ? () => { } : () => { setIsOpen(!isOpen); }}

		// >
		// 	<span className="b3">{props.title}</span>
		// 	<select
		// 		onChange={(e) => {
		// 			console.log('hey');
		// 			optionSelected(e.target.value);
		// 			if (props.onSelect) {
		// 				props.onSelect(e.target.value);
		// 			}
		// 		}}
		// 		ref={selectRef} size={optionBoxSize} style={{ zIndex: '1', top: '100px', marginTop: '0px', position: 'absolute', height: (optionBoxSize === 1) ? '40px' : '', border: 'none', borderRadius: (optionBoxSize === 1) ? '12px' : '', backgroundColor: 'white', }} className="form-select w-100 pointer" aria-label="Default select example" onFocus={() => { setOptionBoxSize(10); }} onBlur={() => { setOptionBoxSize(1); }} >
		// 		{
		// 			props.options && props.options.map((option, key) => {
		// 				return (
		// 					<option key={key} selected={selected === option ? true : false} value={option}>{option}</option>
		// 				);
		// 			})
		// 		}
		// 	</select>


		// </div>
		);
	}
}